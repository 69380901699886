import _styled2 from "styled-components";
import _styled from "styled-components";
import { ContentFilter, ContentFiltersValues, Filters } from 'components/shared/Filters';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Button, ButtonType, Icon, TextField, TextFieldType } from '~/components/core';
import { ConfirmationModal } from '~/components/shared/ConfirmationModal';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
type FilterFilesFormProps = {
  filter: ContentFiltersValues;
  onUploadClick: () => void;
  refetchUploadedFiles: () => void;
  selectedFileCount: number;
  setFilter: (filter: ContentFiltersValues) => void;
  onDeselectAll?: () => void;
};
const FilterFilesForm = ({
  filter,
  setFilter,
  onUploadClick,
  refetchUploadedFiles,
  selectedFileCount,
  onDeselectAll
}: FilterFilesFormProps) => {
  // State
  const [showYoutubeUrlModal, setShowYoutubeUrlModal] = useState<boolean>(false);
  const [youtubeUrlInputValue, setYoutubeUrlInputValue] = useState<string>('');

  // Custom hooks
  const {
    enqueueToast
  } = useToast();

  // Mutations
  const createFileFromYoutubeMutation = nextTrpc.files.createFromYoutubeUrl.useMutation({
    onSuccess: () => {
      setShowYoutubeUrlModal(false);
      refetchUploadedFiles();
    },
    onError: () => {
      setShowYoutubeUrlModal(false);
      enqueueToast({
        message: 'Something went wrong while uploading the file from YouTube.',
        type: ToastType.ERROR
      });
    }
  });

  // TODO: Integrate this with the rest of file uploads, so it can
  // show a progress bar like a normal upload
  const handleUploadYoutubeUrl = () => createFileFromYoutubeMutation.mutate({
    youtubeUrl: youtubeUrlInputValue
  });
  const handleSearchChange = debounce((search: string) => {
    setFilter({
      ...filter,
      [ContentFilter.NAME]: search
    });
  }, 500);
  return <>
      <_StyledDiv>
        <Filters<ContentFilter>
      // TODO SQLINJECTION: Temporarily removed due to SQL injection vector
      // filters={[
      //   {
      //     defaultLabel: 'All',
      //     filterKey: ContentFilter.TYPE,
      //     options: acceptedFileTypes.map((fileType) => ({
      //       label: fileType,
      //       value: fileType
      //     })),
      //     placeholder: 'Type'
      //   },
      //   {
      //     defaultLabel: 'All',
      //     filterKey: ContentFilter.SOURCE,
      //     options: Object.keys(ContentSource).map((source) => ({
      //       label: source,
      //       value: source
      //     })),
      //     placeholder: 'Source'
      //   }
      // ]}
      filters={[]} filtersValues={filter} searchPlaceholder="Search for a file" searchValue={filter.name || ''} setFilters={newFilters => setFilter(newFilters)} setSearchValue={handleSearchChange} />

        <_StyledDiv2>
          {selectedFileCount > 0 && onDeselectAll && <Button label={`Clear ${selectedFileCount} selected files`} onClick={onDeselectAll} startIcon={Icon.CLOSE} type={ButtonType.OUTLINED} />}
          <Button label="Upload from YouTube" onClick={() => setShowYoutubeUrlModal(true)} startIcon={Icon.PLAY} type={ButtonType.OUTLINED} />
          <Button label="Upload file" onClick={onUploadClick} startIcon={Icon.PLUS} type={ButtonType.OUTLINED} />
        </_StyledDiv2>
      </_StyledDiv>
      {showYoutubeUrlModal && <ConfirmationModal confirmButtonLabel="Upload" confirmLoading={createFileFromYoutubeMutation.isLoading} header="Upload a file from a YouTube URL" onCancel={() => setShowYoutubeUrlModal(false)} onConfirm={handleUploadYoutubeUrl} open subtext="The content of the video will be added to your Kindo library as an audio file, and can be used in chat and agents.">
          <TextField fullWidth onChange={value => setYoutubeUrlInputValue(value)} onEnter={handleUploadYoutubeUrl} placeholder="https://www.youtube.com/watch?v=BbcChAL-gAs" type={TextFieldType.OUTLINED} value={youtubeUrlInputValue} />
        </ConfirmationModal>}
    </>;
};
export default FilterFilesForm;
var _StyledDiv = _styled("div").withConfig({
  displayName: "FilterFilesForm___StyledDiv",
  componentId: "sc-8gdcog-0"
})({
  "position": "relative",
  "display": "flex",
  "flexDirection": "row",
  "justifyContent": "space-between",
  "paddingTop": "1rem",
  "paddingBottom": "1rem"
});
var _StyledDiv2 = _styled("div").withConfig({
  displayName: "FilterFilesForm___StyledDiv2",
  componentId: "sc-8gdcog-1"
})({
  "position": "relative",
  "display": "flex",
  "flexDirection": "row",
  "alignItems": "center",
  "gap": "0.5rem"
});