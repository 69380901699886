import _styled2 from "styled-components";
import _styled from "styled-components";
const ProgressContainer = _styled.div.withConfig({
  displayName: "Progress__ProgressContainer",
  componentId: "sc-lhifbp-0"
})({
  "height": "0.625rem",
  "width": "100%",
  "borderRadius": "9999px",
  "backgroundColor": "#8CAEBA33"
});
type ProgressProps = {
  value: number;
};
export const Progress: React.FC<ProgressProps> = ({
  value
}) => <ProgressContainer>
    <_StyledDiv style={{
    width: `${Math.min(value, 100)}%`
  }} />
  </ProgressContainer>;
var _StyledDiv = _styled("div").withConfig({
  displayName: "Progress___StyledDiv",
  componentId: "sc-lhifbp-1"
})({
  "height": "0.625rem",
  "borderRadius": "9999px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(77 114 128 / var(--tw-bg-opacity))"
});