import { EXPRESS_UPLOAD_FILE_DATA_FORM_FIELD_NAME } from '@kindo/universal';
import axios from 'axios';
import { EXPRESS_FILE_UPLOAD_DIRECT_URL } from '~/constants';

// Certain file types are not detected correctly by the browser,
// this is a workaround to fix that by manually setting the type
// for certain extensions
const FILE_EXTENSION_TYPE_OVERRIDES: Record<string, string> = {
  md: 'text/markdown'
};
const uploadWithProgressUpdates = async (url: string, data: FormData, onProgress: (progress: number) => void) => {
  const res = await axios.post(url, data, {
    onUploadProgress: progressEvent => {
      if (!progressEvent.total) return;

      // Note: This is not accurate because it does not account
      // for time in the API to do things like upload to s3,
      // only the time it takes to get to the API
      // Stop at 0.8 to make it seem less broken
      const progress = Math.round(progressEvent.loaded * 100 / progressEvent.total) * 0.8;
      onProgress(progress);
    },
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: true
  });
  return res;
};

// Token is required to bypass the proxy
// the proxy does not work with large files
// eslint-disable-next-line import/prefer-default-export
export async function uploadFile(file: File, onProgress: (progress: number) => void) {
  const data = new FormData();

  // Check if the file required a type override
  const fileExtension = file.name.split('.').pop() || '';
  const fileWithMimeTypeOverride = new File([file], file.name, {
    type: FILE_EXTENSION_TYPE_OVERRIDES[fileExtension] || file.type
  });
  data.append(EXPRESS_UPLOAD_FILE_DATA_FORM_FIELD_NAME, fileWithMimeTypeOverride);
  const res = await uploadWithProgressUpdates(EXPRESS_FILE_UPLOAD_DIRECT_URL, data, onProgress);
  return res;
}