import { ContentListSort } from '@kindo/api';
import { TableSort } from '../../SortHeader/SortHeader';
import { ContentFilter } from '~/components/shared/Filters';
export type ContentTableSort = TableSort<ContentListSort>;
export const DEFAULT_CONTENT_MODAL_SORT: ContentTableSort = {
  key: ContentListSort.CREATED_AT,
  direction: 'desc'
} as const;
export const DEFAULT_CONTENT_FILTER = {
  [ContentFilter.NAME]: '',
  [ContentFilter.TYPE]: undefined,
  [ContentFilter.SOURCE]: undefined
};
export const DEFAULT_LIMIT = 5;