import { SupportedLlm, FeatureFlags, insertIf, FEATURE_FLAG_DISPLAYED_CHAT_LLMS, LLM_DISPLAY_NAMES } from '@kindo/universal';
import { useFlag } from '@unleash/nextjs/client';
import { Select, TypographySize, Color } from '~/components/core';
import { COST_TIER_INDICATOR_TEXT, MODEL_COST_TIERS } from '~/components/Settings/ModelSettings/ModelChoice/ModelChoice.consts';
import { nextTrpc } from '~/trpc';
interface ModelSelectProps {
  chatModel: SupportedLlm;
  onChange: (CHAT_LLM: SupportedLlm) => void;
  placeholderLabel: string;
  prefix: string;
}
export const ModelSelect = ({
  onChange,
  chatModel,
  prefix,
  placeholderLabel
}: ModelSelectProps) => {
  // Queries
  const {
    data: availableModels
  } = nextTrpc.adminSecurityConfig.listOrgAvailableModels.useQuery();

  // TODO: filter using a feature flag on the backend. Right now, Feature Flagged LLMs
  // will show on the selector, but not be usable if turned off in sec. settings
  const canAccessAllChatModels = useFlag(FeatureFlags.FEATURE_FLAGGED_LLMS);
  return <Select<SupportedLlm> onChange={val => onChange(val)} options={[...(availableModels ?? []), ...insertIf(canAccessAllChatModels, ...FEATURE_FLAG_DISPLAYED_CHAT_LLMS)].map(modelOption => ({
    value: modelOption,
    label: LLM_DISPLAY_NAMES[modelOption],
    suffix: {
      text: COST_TIER_INDICATOR_TEXT[MODEL_COST_TIERS[modelOption]],
      color: Color.GRAY,
      size: TypographySize.X_SMALL
    }
  }))} placeholderLabel={placeholderLabel} prefix={prefix} value={chatModel} />;
};
export default ModelSelect;