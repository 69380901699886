import { useEffect, useState } from 'react';
import { ToastType, useToast } from '~/hooks';
export type FileUploadStatus = {
  file: File;
  progress: number;
};
const useFileUploads = ({
  uploadFile,
  onUploadComplete
}: {
  onUploadComplete: () => void;
  uploadFile: (file: File, onProgress: (progress: number) => void) => Promise<any>;
}) => {
  // State
  const [uploads, setUploads] = useState<FileUploadStatus[]>([]);

  // Custom hooks
  const {
    enqueueToast
  } = useToast();
  useEffect(() => {
    const uploadFiles = async () => {
      // Check if there are any new uploads
      const hasNewUploads = uploads.some(({
        progress
      }) => progress === 0);
      if (!hasNewUploads) {
        return;
      }

      // For each new upload, create a promise that will upload the file
      const uploadPromises = uploads.filter(({
        progress
      }) => progress === 0).map(async ({
        file
      }) => {
        // Callback that updates the upload progress
        const onProgress = (progress: number) => {
          setUploads(previousUploads => previousUploads.map(previousUpload => previousUpload.file === file ? {
            ...previousUpload,
            progress
          } : previousUpload));
        };

        // Upload the file
        await uploadFile(file, onProgress);

        // Remove the upload from the list of uploads
        setUploads(previousUploads => previousUploads.filter(previousUpload => previousUpload.file !== file));

        // Call upload complete callback
        onUploadComplete();
      });

      // Wait for all uploads to finish
      try {
        await Promise.all(uploadPromises);
      } catch (e) {
        console.error('Failed to upload files', e);
        enqueueToast({
          type: ToastType.ERROR,
          message: 'An error occurred while uploading your files.'
        });
      }
    };
    void uploadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploads.length]);
  return {
    uploads,
    addUploads: (files: File[]) => {
      setUploads([...uploads, ...files.map(file => ({
        file,
        progress: 0,
        completed: false
      }))]);
    }
  };
};
export default useFileUploads;