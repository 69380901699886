import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { ContentListSort } from '@kindo/api';
import { FileType, Llm, MERGE_DOWNLOAD_FILE_SIZE_LIMIT, getModelSupportedFileTypes, isGeminiChatLlm, isMergeIntegration } from '@kindo/universal';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ContentSource } from '../../Library/components/FilesTab/Files.types';
import { ContentTableSort, DEFAULT_CONTENT_FILTER, DEFAULT_CONTENT_MODAL_SORT, DEFAULT_LIMIT } from './AddFileModal.consts';
import { FileResource } from './AddFileModal.types';
import { uploadFile } from './AddFileModal.utils';
import { FILE_TABLE_COLUMNS, FileResourceWithTableMetadata, getFileRow, getFileUploadRow, shouldShowFileTranscriptionDownload } from './AddFileModalComponentUtils';
import { FileUploader, useFileUploads } from './FileUploader';
import { FilterFilesForm } from './FilterFilesForm';
import { Pagination } from './Pagination';
import { Button, ButtonType, Color, Modal, ModalWidth, Table } from '~/components/core';
import { ContentFiltersValues } from '~/components/shared/Filters';
import { nextTrpc, trpc } from '~/trpc';
export type ContentItem = Awaited<ReturnType<(typeof trpc)['content']['list']['query']>>['items'][number];
const convertContentItemToFileResource = (contentItem: ContentItem, activeModel: Llm, modelSupportedFileTypes: FileType[]): FileResourceWithTableMetadata => ({
  id: contentItem.id,
  name: decodeURIComponent(contentItem.filename),
  fileType: contentItem.fileType,
  source: contentItem.integrationName as ContentSource,
  createdAt: contentItem.createdAt,
  hasPlaintextTranscription: !!contentItem.plaintextUrn,
  // For Gemini, an file cannot be used until it has been uploaded to GCS
  isUploadedToCorrespondingCloud: isGeminiChatLlm(activeModel) ? !!contentItem.gcsUri : !!contentItem.s3Key,
  isSupportedByModel: contentItem.fileType !== undefined && modelSupportedFileTypes.includes(contentItem.fileType),
  isTooLargeToDownloadFromMerge: !contentItem.s3Key && isMergeIntegration(contentItem.integrationName as any) && contentItem.sizeBytes !== null && contentItem.sizeBytes > MERGE_DOWNLOAD_FILE_SIZE_LIMIT
});
type AddFileModalProps = Prettify<{
  activeModel: Llm;
  initialSelectedFiles: FileResource[];
  onClose: () => void;
  onSelect: (files: FileResource[]) => void;
  // Only select one file at a time
  className?: string;
  selectedFiles?: FileResource[];
  singleSelection?: boolean;
}>;
export const AddFileModal = ({
  onSelect,
  onClose,
  initialSelectedFiles,
  singleSelection = false,
  activeModel
}: AddFileModalProps) => {
  // State
  const [filter, setFilter] = useState<ContentFiltersValues>(DEFAULT_CONTENT_FILTER);
  const [sort, setSort] = useState<ContentTableSort>(DEFAULT_CONTENT_MODAL_SORT);
  const [selected, setSelected] = useState<FileResource[]>(initialSelectedFiles);
  const [page, setPage] = useState(0);
  const [shouldPollData, setShouldPollData] = useState<boolean>(false);

  // Ref
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const limit = DEFAULT_LIMIT;
  const acceptedFileTypes: FileType[] = getModelSupportedFileTypes(activeModel);
  const {
    data,
    refetch: refetchUploadedFiles,
    isLoading
  } = nextTrpc.content.list.useQuery({
    limit,
    skip: page * limit,
    filter: {
      textSearch: filter.name,
      fileTypes: filter.type ? [filter.type as FileType] : undefined
    },
    sort: sort.key,
    sortDesc: sort.direction === 'desc'
  }, {
    refetchInterval: shouldPollData ? 3000 : false,
    select: newData => {
      const itemsAsFiles = newData?.items?.map(item => convertContentItemToFileResource(item, activeModel, acceptedFileTypes));
      return {
        ...newData,
        items: itemsAsFiles
      };
    },
    refetchOnWindowFocus: false
  });

  // Create a debounced version of the refetchUploadedFiles function
  // The debounce function delays the execution of refetchUploadedFiles by 500ms
  // This helps to reduce the number of API requests made while the user is typing
  // And stops refresh jank when the user is typing
  const debouncedRefetchUploadedFiles = () => debounce(refetchUploadedFiles, 500);

  // Wrap in useMemo to avoid infinite re-renders in useEffect
  const files: FileResourceWithTableMetadata[] = useMemo(() => data?.items ?? [], [data?.items]);
  const selectedButNotOnCurrentPage = useMemo(() => selected.filter(s => !files.map(f => f.id).includes(s.id)),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [files.length, selected.length]);

  // Check for unprocessed files
  useEffect(() => {
    if (files.some(file => {
      const {
        shouldShowTranscriptionDownload,
        isTranscriptionReady
      } = shouldShowFileTranscriptionDownload(file);
      return file.isUploadedToCorrespondingCloud || shouldShowTranscriptionDownload && !isTranscriptionReady;
    })) {
      setShouldPollData(true);
    } else {
      setShouldPollData(false);
    }
  }, [files]);
  const {
    uploads,
    addUploads
  } = useFileUploads({
    uploadFile: async (file, onProgress) => await uploadFile(file, onProgress),
    onUploadComplete: () => {
      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
      void refetchUploadedFiles();
    }
  });
  return <Modal noContentPadding onClose={() => onClose()} open width={ModalWidth.NINE_TWENTY}>
      <_StyledDiv>
        <_StyledDiv2>
          <_StyledDiv3>
            <_StyledDiv4>
              <_StyledH>
                Add a file
              </_StyledH>
              <FilterFilesForm filter={filter} onDeselectAll={() => setSelected([])} onUploadClick={() => {
              inputFileRef.current?.click();
            }} refetchUploadedFiles={debouncedRefetchUploadedFiles} selectedFileCount={selected.length} setFilter={filters => {
              setFilter(filters);
              setPage(0);
            }} />
            </_StyledDiv4>
            <_StyledFileUploader acceptedFileTypes={acceptedFileTypes} onChange={addUploads} onLoad={ref => {
            inputFileRef.current = ref.current;
          }} />
            <Table<string, string, ContentListSort> borderColor={Color.GRAY_LIGHT} columns={FILE_TABLE_COLUMNS} noRowsText={isLoading ? 'Loading...' : 'No files found'} rows={[...uploads.map(upload => getFileUploadRow(upload)), ...files.map(file => getFileRow(file))]} selectionProps={{
            selectedItems: selected.map(({
              id
            }) => id),
            setSelectedItems: newSelected => {
              setSelected([...selectedButNotOnCurrentPage, ...files.filter(({
                id
              }) => newSelected.includes(id))]);
            },
            singleSelection
          }} showColumnHeaderBorder sortProps={{
            activeSort: sort,
            setActiveSort: value => setSort(value)
          }} />
          </_StyledDiv3>
        </_StyledDiv2>
        <_StyledDiv5>
          <Pagination initialPage={page} onPageActive={() => {}} onPageChange={setPage} totalPages={data?.totalPages || 1} />
          <_StyledDiv6>
            <Button label="Cancel" onClick={() => onClose?.()} type={ButtonType.TEXT} />
            <Button disabled={!selected.length} label="Add File" onClick={() => {
            onSelect?.(selected);
            onClose?.();
          }} type={ButtonType.FILLED} />
          </_StyledDiv6>
        </_StyledDiv5>
      </_StyledDiv>
    </Modal>;
};
export default AddFileModal;
var _StyledDiv = _styled("div").withConfig({
  displayName: "AddFileModal___StyledDiv",
  componentId: "sc-2pph61-0"
})({
  "position": "relative",
  "display": "flex",
  "flexDirection": "column"
});
var _StyledDiv2 = _styled("div").withConfig({
  displayName: "AddFileModal___StyledDiv2",
  componentId: "sc-2pph61-1"
})({
  "display": "flex",
  "flexDirection": "row"
});
var _StyledDiv3 = _styled("div").withConfig({
  displayName: "AddFileModal___StyledDiv3",
  componentId: "sc-2pph61-2"
})({
  "display": "flex",
  "maxHeight": "75vh",
  "flexBasis": "100%",
  "flexDirection": "column",
  "justifyItems": "stretch",
  "> :not([hidden]) ~ :not([hidden])": {
    "--tw-space-y-reverse": "0",
    "marginTop": "calc(0.5rem * calc(1 - var(--tw-space-y-reverse)))",
    "marginBottom": "calc(0.5rem * var(--tw-space-y-reverse))"
  }
});
var _StyledDiv4 = _styled("div").withConfig({
  displayName: "AddFileModal___StyledDiv4",
  componentId: "sc-2pph61-3"
})({
  "paddingLeft": "1.5rem",
  "paddingRight": "1.5rem"
});
var _StyledH = _styled("h1").withConfig({
  displayName: "AddFileModal___StyledH",
  componentId: "sc-2pph61-4"
})({
  "fontSize": "1.5rem",
  "lineHeight": "2rem",
  "fontWeight": "350",
  "--tw-text-opacity": "1",
  "color": "rgb(22 25 37 / var(--tw-text-opacity))"
});
var _StyledFileUploader = _styled(FileUploader).withConfig({
  displayName: "AddFileModal___StyledFileUploader",
  componentId: "sc-2pph61-5"
})({
  "height": "100%"
});
var _StyledDiv5 = _styled("div").withConfig({
  displayName: "AddFileModal___StyledDiv5",
  componentId: "sc-2pph61-6"
})({
  "display": "flex",
  "justifyContent": "space-between",
  "borderBottomRightRadius": "0.5rem",
  "borderBottomLeftRadius": "0.5rem",
  "borderTopWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(230 232 239 / var(--tw-border-opacity))",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(243 244 247 / var(--tw-bg-opacity))",
  "padding": "1rem"
});
var _StyledDiv6 = _styled("div").withConfig({
  displayName: "AddFileModal___StyledDiv6",
  componentId: "sc-2pph61-7"
})({
  "display": "flex",
  "alignItems": "center"
});